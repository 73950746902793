import React from "react"
import { Link } from "gatsby"
import {
  Button,
  Row,
  Col,
  Container,
  Accordion,
  Card,
  Carousel,
} from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FavoriteIcon from "@material-ui/icons/Favorite"
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun"
import EventAvailableIcon from "@material-ui/icons/EventAvailable"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"

const events = [
  "Weddings",
  "Anniversaries",
  "Birthdays",
  "Baby Showers",
  "Corporate events",
  "Small and intimate events",
  "Celebrations",
  "Holiday decorating",
]

const eventsCarousel = (
  <Carousel fade controls={false} indicators={false} interval={2000}>
    {events.map((event, i) => (
      <Carousel.Item key={i}>
        <h5 className="events-words">{event}</h5>
      </Carousel.Item>
    ))}
  </Carousel>
)

const inHouseList = [
  [
    "EXERCISE & YOGA",
    "Whether it's yoga, Pilates, boot camp, trail running, boxing, weight lifting, or martial arts. Let us bring you the best the valley has to offer in whatever you desire for a sweat session. Please inquire on pricing, it's all dependent on the service and materials required.",
  ],
  [
    "SPA SERVICES",
    "Inquire with us to customize an in-house spa experience! We know the best of the best in the valley to make sure you feel pampered and relaxed.",
  ],
  [
    "BABYSITTING",
    "Whether it's for a night out or for a week long trip, let us find you the perfect sitter for your precious little ones. Price dependent on needs and number of children, please inquire.",
  ],
]
const inHouseAccList = inHouseList.map((service, i) => (
  <Accordion key={i}>
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey="1"
        className="accordion_header"
      >
        {service[0]}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body className="accordion_contents"> {service[1]}</Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
))

const galleryLink = (
  <Link to="/gallery" className="link-no-style">
    <p className="gallery-link">Photo Gallery</p>
  </Link>
)

const planningList = [
  [
    "ITINERARY / SCHEDULING",
    "Home or condo rental, dinner reservations, lift ticket purchase, gear rentals, activity arrangements, ski lessons, and transportation reservations.",
  ],
  [
    "TRANSPORTATION",
    "We know how to get around the box canyon. Let's get you where you wanna go safely and comfortably.",
  ],
  [
    "EVENT PLANNING",
    `Let us help you take the stress out of your Telluride event. We know the vendors, the locations, we know Telluride. This is your big event, let us handle the details for you. Check out our`,
    galleryLink,
  ],
]
const planningAccList = planningList.map((service, i) => (
  <Accordion>
    <Card key={i}>
      <Accordion.Toggle
        as={Card.Header}
        eventKey="1"
        className="accordion_header"
      >
        {service[0]}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body className="accordion_contents">
          {service[1]}
          {service[2]}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
))
const activitiesList = [
  [
    "HIKING GUIDE",
    "Includes travel by capable 4x4 vehicle to trail head, knowledgeable guide service with fun historical facts, packed lunch, and gorgeous trails!",
  ],
  [
    "MUSHROOM FORAGE",
    "Includes travel by capable 4x4 vehicle to trail head, knowledgeable guide on fungi, packed lunch, and (hopefully) a bounty of wild mushrooms. *Ask us about arranging a private chef to come to your home or residence to prepare a meal using the mushrooms foraged by your group.",
  ],
  [
    "NIGHT IN WITH FRIENDS & FAMILY",
    "Enjoy the coziness of your own space. Allow us to bring in a private chef, beverages of your choice, games for everyone, music, and decorations as needed. Inquire for pricing, dependent on needs.",
  ],
]
const activitiesAccList = activitiesList.map((service, i) => (
  <Accordion>
    <Card key={i}>
      <Accordion.Toggle
        as={Card.Header}
        eventKey="1"
        className="accordion_header"
      >
        {service[0]}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body className="accordion_contents"> {service[1]}</Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
))

const shoppingList = [
  [
    "GROCERY SHOPPING",
    "Let us do the grocery shopping for you. Arrive to your home or condo to a fully stocked fridge and pantry. We can even purchase your beer, wine, and spirits.",
  ],
  [
    "PERSONAL SHOPPER",
    "We can purchase gear for all your mountain activities (skis, boards, bikes, stand up paddle boards, climbing gear, camping, hiking, nordic skiing, ice climbing, warm layers, gifts, flowers, etc... )",
  ],

  [
    "PRIVATE CHEF/CATERING",
    "Inquire for the best chefs in the area to safely provide meals in your home",
  ],
]
const shoppingAccList = shoppingList.map((service, i) => (
  <Accordion>
    <Card key={i}>
      <Accordion.Toggle
        as={Card.Header}
        eventKey="1"
        className="accordion_header"
      >
        {service[0]}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body className="accordion_contents">{service[1]}</Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>
))

const ServicesPage = () => (
  <Layout pageInfo={{ pageName: "services" }}>
    <SEO title="Services" />

    <Container className="main-container">
      <Container className="main-container">
        <h1 className="about-header">What We Do For You</h1>
        <Row>
          <Col className="content">
            Let us customize every aspect of your Telluride itinerary according
            to you and your group's needs.
          </Col>
        </Row>

        <Row>
          <Col className="content">
            We will help you with any reason you have to celebrate!
          </Col>
        </Row>
        <Row className="events-carousel">
          <Col md="6">{eventsCarousel}</Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className="service-card" md={6}>
            <Row>
              <Col>
                <EventAvailableIcon
                  style={{ color: "purple", fontSize: "40px" }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="group-title">Planning + Logistics</Col>
            </Row>
            <Row>
              <Col>{planningAccList}</Col>
            </Row>
          </Col>

          <Col className="service-card" md={6}>
            <Row>
              <Col>
                <ShoppingCartIcon
                  style={{ color: "green", fontSize: "40px" }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="group-title">Shopping</Col>
            </Row>
            <Row>
              <Col>{shoppingAccList}</Col>
            </Row>
          </Col>

          <Col className="service-card" md={6}>
            <Row>
              <Col>
                <DirectionsRunIcon
                  style={{ color: "blue", fontSize: "40px" }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="group-title">Activities</Col>
            </Row>
            <Row>
              <Col>{activitiesAccList}</Col>
            </Row>
          </Col>

          <Col className="service-card" md={6}>
            <Row>
              <Col>
                <FavoriteIcon style={{ color: "red", fontSize: "40px" }} />
              </Col>
            </Row>
            <Row>
              <Col className="group-title">In-House / At-Home</Col>
            </Row>
            <Row>
              <Col>{inHouseAccList}</Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="main-container">
        <Row>
          <Col className="content">
            <p style={{ color: "grey" }}>
              Please inquire for pricing. We can help create packages within
              your desired budget.
              <br />
              <strong>All services have a one hour minimum.</strong>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <Button
                className="btn btn-block to-contact"
                type="submit"
                variant="success"
              >
                Plan your trip with us!
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default ServicesPage
